<template>
  <li>
    <item-card :url="`/stock/tehnical_documents/${item.id}`" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <template v-if="selectedTypeId === 5 && item.status">
          <Badge :text="item.status.name" :className="`${statusColor(item.status)} mr-2 mb-2 md:mb-0`" />
        </template>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          {{item.uuid}} <span v-if="item.documentable.reason" class="font-light"> - {{item.documentable.reason}}</span>
        </h3>
      </template>

      <template v-slot:content>

        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2">
          <item-text title="Datums" :text="item.document_date" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2">
          <item-text title="Autors" :text="item.user" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2">
          <item-text title="Tips" :text="item.documentable.type_name" />
        </div>

          <div v-if="item.sender" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
              <item-text title="No" :text="item.sender.name" />
          </div>

          <div v-if="item.receiver" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
              <item-text title="Uz" :text="item.receiver.name" />
          </div>

      </template>
    </item-card>
  </li>
</template>

<script>
import Colors from "@/services/Helpers/colors";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
  name: "TehnicalDocumentsListItem",
  data() {
    return {
      showStockItemDetails: false,
    }
  },
  components: {
    ItemCard,
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    selectedTypeId: {
      type: Number,
      required: true
    },
  },
  methods: {
    statusColor(status) {
      let key = status ? status.id : 0
      return Colors.statusColor(key)
    }
  },
}
</script>

<style>

</style>